import { Feature } from 'flagged';
import { useState } from 'react';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { useDataQuery } from '../../hooks/use-data.hook';
import { ApiConfig, DataOptions, TimeRangeMap, Indicator } from '../../types';
import { FilterFeatures } from '../FilterFeatures';
import { Table } from '../Table';
import { Spinner } from '../Spinner';
import { useTitle } from '../../hooks/use-title.hook';
import { useTranslation } from 'react-i18next';
import { getRouteApi, Link } from '@tanstack/react-router';

const routeApi = getRouteApi('/table/');

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

export function TablePage(props: Props) {
    const { t } = useTranslation();
    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const selectedFeatures = search.selectedFeatures ?? [];

    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');

    useTitle('Tabell');

    const featureFilter = search.featureFilter ?? [];

    const timeRangeMap = props.indicators.reduce((obj, curr) => {
        if (!curr.timeRanges) {
            console.error(
                `Timerange not found in indicator ${JSON.stringify(curr)}`
            );
            return obj;
        }

        return {
            ...obj,
            [curr.id]: curr.timeRanges,
        };
    }, {} as TimeRangeMap);

    const indicatorsMap = (props.indicators ?? []).reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as Record<string, Indicator>);

    const [targetOptions, setTargetOptions] = useState<DataOptions>({
        indicators: {
            xVar: indicatorsMap[
                search.indicators?.x ?? props.config?.defaultDataPackage.x
            ],
            yVar: indicatorsMap[
                search.indicators?.y ?? props.config?.defaultDataPackage.y
            ],
            rVar: indicatorsMap[
                search.indicators?.r ?? props.config?.defaultDataPackage.r
            ],
            cVar: indicatorsMap[
                search.indicators?.c ?? props.config?.defaultDataPackage.c
            ],
        },
        geoLevel: search.geoLevel,
    });

    const dataQuery = useDataQuery(targetOptions, indicatorsMap);

    if (!dataQuery.data) return <Spinner loading />;

    const { points, options } = dataQuery.data;

    let filteredPoints = points.filter((d) => {
        return featureIsIncludedInCurrentFilter(d, featureFilter);
    });

    if (showOnlySelected && selectedFeatures.length > 0) {
        filteredPoints = filteredPoints.filter((d) => {
            return selectedFeatures.includes(d.properties?.id);
        });
    }

    if (query) {
        filteredPoints = filteredPoints.filter((d) => {
            return (
                d.properties?.kommunnamn.toLowerCase().includes(query) ||
                d.properties?.[options.geoLevel].toLowerCase().includes(query)
            );
        });
    }

    const showOnlySelectedDisabled = selectedFeatures.length === 0;

    return (
        <div>
            <div className="flex flex-row justify-between py-3 px-5">
                <div className="mt-2 px-2 link">
                    <Link to="/" search={search}>
                        {'< '}
                        {t('back')}
                    </Link>
                </div>
                <div className="flex gap-12">
                    <div className="flex flex-col gap-3 justify-start relative">
                        <div>
                            <input
                                type="text"
                                placeholder={t('search') as string}
                                className="input input-sm input-bordered w-full max-w-sm"
                                value={query}
                                onChange={(e) => {
                                    setQuery(e.target.value.toLowerCase());
                                }}
                            />
                        </div>
                        <div
                            className={
                                showOnlySelectedDisabled
                                    ? 'tooltip tooltip-left tooltip-warning text-warning-content'
                                    : ''
                            }
                            data-tip={t('inga-punkter-markerade')}
                        >
                            <label
                                className={`flex flex-row gap-2 ${
                                    showOnlySelectedDisabled
                                        ? 'cursor-not-allowed'
                                        : 'cursor-pointer'
                                } text-sm items-center`}
                            >
                                <input
                                    type="checkbox"
                                    checked={showOnlySelected}
                                    disabled={showOnlySelectedDisabled}
                                    onChange={() =>
                                        setShowOnlySelected(!showOnlySelected)
                                    }
                                    className="checkbox checkbox-xs checkbox-primary"
                                />
                                {t('show-selected-only')}
                            </label>
                        </div>

                        {selectedFeatures.length > 0 && (
                            <button
                                onClick={() => {
                                    navigate({
                                        search: (old) => ({
                                            ...old,
                                            selectedFeatures: [],
                                        }),
                                    });
                                    setShowOnlySelected(false);
                                }}
                                type="button"
                                className="btn btn-xs btn-primary absolute bottom-0 right-full mr-3"
                                style={{ height: '32px' }}
                            >
                                {t('rensa-markering')}
                            </button>
                        )}
                    </div>
                    <div className="flex gap-3">
                        <h2 className="capitalize">{t('filter')}</h2>
                        <FilterFeatures
                            featureFilter={featureFilter}
                            onChangeFilter={(newFilter) => {
                                navigate({
                                    search: (old) => ({
                                        ...old,
                                        featureFilter: newFilter,
                                    }),
                                });
                            }}
                            onToggleZoomToFiltered={() => {
                                navigate({
                                    search: (old) => ({
                                        ...old,
                                        zoomToFiltered: !old.zoomToFiltered,
                                        animationBuster: new Date().getTime(),
                                    }),
                                });
                            }}
                            zoomToFiltered={search.zoomToFiltered}
                            points={points}
                        />
                    </div>
                </div>
            </div>
            <Feature name="SHOW_TABLE">
                <Table
                    dataFeatures={filteredPoints}
                    dataOptions={options}
                    geoLevel={targetOptions.geoLevel}
                    timeRangeMap={timeRangeMap}
                    selectedFeatures={selectedFeatures}
                    featureFilter={search.featureFilter}
                    onSelectFeature={(_selectedFeatures) => {
                        navigate({
                            search: (old) => ({
                                ...old,
                                selectedFeatures: _selectedFeatures,
                            }),
                        });
                    }}
                />
            </Feature>
        </div>
    );
}

export default TablePage;
