import { getRouteApi } from '@tanstack/react-router';
import { TRANSITION_DURATION_MS } from '../../constants';
import { PauseIcon, PlayIcon, ReplayIcon } from '../Icons/PlayIcons';
import { TimeSlider } from './TimeSlider';
import { usePlayer } from './use-player.hook';
import { useEffect, useRef } from 'react';

const routeApi = getRouteApi('/');

type Props = {
    year: number;
    timeRange: number[];
};

export function TimeLine({ year, timeRange }: Props) {
    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const yearRef = useRef<number>(year);
    yearRef.current = year;

    const player = usePlayer({
        min: timeRange[0],
        max: timeRange[1],
        duration: TRANSITION_DURATION_MS * (timeRange[1] - timeRange[0]),
        startValue: search.year,
        onNewValue: ({ value, manual }) => {
            const newValue = Math.ceil(value);

            if (newValue === yearRef.current) {
                return;
            }

            let animationBuster: number | undefined = search.animationBuster;
            if (manual) {
                animationBuster = new Date().getTime();
            }
            navigate({
                search: {
                    ...search,
                    year: newValue,
                    animationBuster,
                },
                replace: true,
            });
        },
    });

    function handlePlayButtonClick() {
        switch (player.state) {
            case 'paused': {
                player.play();
                break;
            }
            case 'stopped': {
                player.reset();
                break;
            }
            case 'playing': {
                player.pause();
                break;
            }
        }
    }

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === ' ') {
                event.preventDefault();
                handlePlayButtonClick();
            }

            if (event.key === 'ArrowLeft' && player.value > timeRange[0]) {
                event.preventDefault();
                player.setValue(yearRef.current - 1);
            }

            if (event.key === 'ArrowRight' && player.value < timeRange[1]) {
                event.preventDefault();
                player.setValue(yearRef.current + 1);
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <div className="relative inset-0 p-3 pointer-events-none overlay">
            <div className="flex flex-col gap-3">
                <div className="flex flex-col items-center">
                    <div className="flex items-center gap-2 w-full">
                        <button
                            className="btn btn-sm btn-circle fill-white"
                            onClick={handlePlayButtonClick}
                        >
                            {player.state === 'stopped' && <ReplayIcon />}
                            {player.state === 'paused' && <PlayIcon />}
                            {player.state === 'playing' && <PauseIcon />}
                        </button>
                        <TimeSlider
                            min={timeRange[0]}
                            max={timeRange[1]}
                            value={player.value}
                            onChange={(value) => {
                                player.setValue(value);
                            }}
                            step={0.01}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
