import { Suspense, lazy, useState } from 'react';
import { useDataQuery } from '../../hooks/use-data.hook';
import {
    ApiConfig,
    DataOptions,
    DataPackage,
    Indicator,
    TimeRangeMap,
} from '../../types';
import { ColorLegend } from '../ColorLegend';
import { TimeLine } from '../TimeLine';
import { ViewPicker } from '../ViewPicker';

import { getRouteApi } from '@tanstack/react-router';
import { useMediaQuery } from 'usehooks-ts';
import { useTitle } from '../../hooks/use-title.hook';
import { Spinner } from '../Spinner';
import { Menu } from './Menu';
import { Options } from './Options';
import { getMinimumTimerange } from '../../get-minimumtimerange';

const routeApi = getRouteApi('/');

const Viz = lazy(() => import('../Viz/Viz'));

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

function findIndicator(options: {
    idOption1?: string;
    idOption2?: string;
    indicatorMap: Record<string, Indicator>;
}) {
    let indicator: Indicator | null = null;
    if (options.idOption1) {
        indicator = options.indicatorMap[options.idOption1];
    }
    if (!indicator && options.idOption2) {
        indicator = options.indicatorMap[options.idOption2];
    }

    if (!indicator) {
        console.error(
            `Indicator not found in options ${JSON.stringify(options)}`
        );
        throw new Error('Indicator not found');
    }

    return indicator;
}

export function Tool(props: Props) {
    useTitle('Huddingeanalysen');

    const timeRangeMap = props.indicators.reduce((obj, curr) => {
        if (!curr.timeRanges) {
            console.error(
                `Timerange not found in indicator ${JSON.stringify(curr)}`
            );
            return obj;
        }

        return {
            ...obj,
            [curr.id]: curr.timeRanges,
        };
    }, {} as TimeRangeMap);

    const indicatorsMap = props.indicators.reduce((obj, curr) => {
        obj[curr.id] = curr;
        return obj;
    }, {} as Record<string, Indicator>);

    const isNarrow = useMediaQuery('(max-width: 640px)');

    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();
    const { geoLevel } = search;

    const [showScatterPaths, setShowScatterPaths] = useState<boolean>(false);
    const [showLabelsSelected, setShowLabelsSelected] = useState<boolean>(true);

    const sIndicators = search.indicators ?? props.config.defaultDataPackage;

    const [xVar, setXVar] = useState<Indicator>(
        findIndicator({
            idOption1: sIndicators.x,
            idOption2: props.config.defaultDataPackage.x,
            indicatorMap: indicatorsMap,
        })
    );
    const [yVar, setYVar] = useState<Indicator>(
        findIndicator({
            idOption1: sIndicators.y,
            idOption2: props.config.defaultDataPackage.y,
            indicatorMap: indicatorsMap,
        })
    );
    const [rVar, setRVar] = useState<Indicator>(
        findIndicator({
            idOption1: sIndicators.r,
            idOption2: props.config.defaultDataPackage.r,
            indicatorMap: indicatorsMap,
        })
    );
    const [cVar, setCVar] = useState<Indicator | null>(
        findIndicator({
            idOption1: sIndicators.c ?? undefined,
            idOption2: props.config.defaultDataPackage.c,
            indicatorMap: indicatorsMap,
        })
    );

    const [notSelectedOpacity, setNotSelectedOpacity] = useState<{
        features: number;
        lines: number;
    }>({ features: 0.4, lines: 0.2 });
    const [labelSize, setLabelSize] = useState<number>(16);
    const [circleSize, setCircleSize] = useState<{ min: number; max: number }>({
        min: 4,
        max: 15,
    });

    const targetOptions: DataOptions = {
        indicators: {
            xVar,
            yVar,
            rVar,
            cVar,
        },
        geoLevel: search.geoLevel,
    };

    const dataQuery = useDataQuery(targetOptions, indicatorsMap);

    const kommunQuery = useDataQuery(
        {
            ...targetOptions,
            geoLevel: 'kommun',
        },
        indicatorsMap
    );

    if (dataQuery.error) {
        return (
            <div className="fixed inset-0 grid place-items-center">
                <div className="flex flex-col gap-1">
                    Oops!
                    <a href="/" className="link-secondary">
                        reset
                    </a>
                </div>
            </div>
        );
    }

    if (!dataQuery.data) {
        return <Spinner loading={true} />;
    }

    // Set the indicators in the query string if they have changed
    const searchIndicators = Object.values(search.indicators ?? {}).filter(
        (d) => d
    ) as string[];
    const dataOptionsIndicators = Object.values(
        dataQuery.data.options.indicators
    )
        .map((d) => d?.id)
        .filter((d) => d) as string[];
    let hasChanged = false;
    for (const indicator of dataOptionsIndicators) {
        if (!searchIndicators.includes(indicator)) {
            hasChanged = true;
            break;
        }
    }
    if (hasChanged) {
        setTimeout(() => {
            navigate({
                replace: true,
                search: {
                    ...search,
                    indicators: {
                        x: xVar.id,
                        y: yVar.id,
                        r: rVar.id,
                        c: cVar?.id,
                    },
                    animationBuster: Date.now(),
                },
            });
        }, 0);
    }

    const timeRange = getMinimumTimerange({
        dataOptions: dataQuery.data.options,
        timeRangeMap,
    });

    const dataPackages = props.config.dataPackages;

    const selectedDataPackage =
        dataPackages.find((datapackage) => {
            if (!xVar || !yVar || !rVar) {
                return false;
            }
            return (
                xVar.id === datapackage?.x &&
                yVar.id === datapackage?.y &&
                rVar.id === datapackage?.r &&
                (!cVar || cVar.id === datapackage?.c)
            );
        }) ?? null;

    const selectedFeatures = search.selectedFeatures ?? [];
    function setSelectedFeatures(features: string[]) {
        navigate({
            replace: true,
            search: {
                ...search,
                selectedFeatures: features,
            },
        });
    }

    function handleSetSelectedDatapackage(newPackage: DataPackage) {
        const newPackageMissingIndicators =
            !newPackage.x || !newPackage.y || !newPackage.r;
        const indicatorsMapMissingIndicators = [
            newPackage.x,
            newPackage.y,
            newPackage.r,
        ].some((d) => !indicatorsMap[d]);

        if (newPackageMissingIndicators || indicatorsMapMissingIndicators) {
            console.error(
                `Missing indicators in newPackage:\n${JSON.stringify(
                    newPackage,
                    null,
                    4
                )}\n\nor indicatorsMap:\n${JSON.stringify(
                    indicatorsMap,
                    null,
                    4
                )}`
            );
            return;
        }

        setXVar(indicatorsMap[newPackage.x]);
        setYVar(indicatorsMap[newPackage.y]);
        setRVar(indicatorsMap[newPackage.r]);
        setCVar(indicatorsMap[newPackage.c]);
    }

    let { points, polygons, options } = dataQuery.data;

    return (
        <div className="h-full p-3 bg-base-300 flex flex-col gap-3 overflow-x-hidden">
            <div className="flex flex-row gap-3 flex-1 h-full min-h-0 relative">
                <Menu
                    indicators={props.indicators ?? []}
                    config={props.config}
                    dataPackages={dataPackages}
                    selectedDataPackage={selectedDataPackage}
                    timeRangeMap={timeRangeMap}
                    timeRange={timeRange}
                    dataOptions={dataQuery.data.options}
                    onSetXVar={setXVar}
                    onSetYVar={setYVar}
                    onSetRVar={setRVar}
                    onSetCVar={setCVar}
                    data={dataQuery.data}
                    indicatorsMap={indicatorsMap}
                    onSetSelectedDatapackage={handleSetSelectedDatapackage}
                    onSetSelectedFeatures={setSelectedFeatures}
                    points={points}
                />

                <div className="flex flex-col h-full w-full gap-3">
                    {/* Main visualization */}
                    <div className="bg-white h-full flex-1 rounded-lg relative">
                        <Suspense fallback={<Spinner loading />}>
                            <Viz
                                dataOptions={options}
                                dataFeatures={points}
                                polygons={polygons}
                                kommunData={kommunQuery.data}
                                year={search.year ?? timeRange[0]}
                                selectedFeatures={selectedFeatures}
                                onClearSelection={() => setSelectedFeatures([])}
                                onFeatureClick={(id) => {
                                    if (!selectedFeatures.includes(id)) {
                                        setSelectedFeatures([
                                            ...selectedFeatures,
                                            id,
                                        ]);
                                    } else {
                                        setSelectedFeatures(
                                            selectedFeatures.filter(
                                                (_id) => _id !== id
                                            )
                                        );
                                    }
                                }}
                                showScatterPaths={showScatterPaths}
                                notSelectedOpacity={notSelectedOpacity}
                                circleSize={circleSize}
                                showLabelsSelected={showLabelsSelected}
                                labelSize={labelSize}
                                viewStateInUrl={true}
                                timeRangeMap={timeRangeMap}
                                animationBuster={search.animationBuster}
                                featureFilter={search.featureFilter}
                                zoomToFiltered={search.zoomToFiltered}
                                mapType={search.mapType}
                                view={search.view}
                                onViewStateChange={(viewState) => {
                                    navigate({
                                        replace: true,
                                        search: {
                                            ...search,
                                            ...viewState,
                                        },
                                    });
                                }}
                                colorScale={search.colorScale}
                                colorType={search.colorType}
                                scaleType={search.scaleType}
                            />
                        </Suspense>
                    </div>
                    <div className="flex gap-3">
                        <div className="flex flex-col gap-3 flex-1">
                            <div className="flex justify-between">
                                <div className="flex-1 flex justify-center items-start gap-6">
                                    <ViewPicker />
                                </div>
                                <Options
                                    circleSize={circleSize}
                                    setCircleSize={setCircleSize}
                                    labelSize={labelSize}
                                    setLabelSize={setLabelSize}
                                    notSelectedOpacity={notSelectedOpacity}
                                    setNotSelectedOpacity={
                                        setNotSelectedOpacity
                                    }
                                    showLabelsSelected={showLabelsSelected}
                                    setShowLabelsSelected={
                                        setShowLabelsSelected
                                    }
                                    showScatterPaths={showScatterPaths}
                                    setShowScatterPaths={setShowScatterPaths}
                                    selectedFeatures={selectedFeatures}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="bg-base-100 rounded-lg flex-1">
                    <TimeLine
                        year={search.year ?? timeRange[0]}
                        timeRange={timeRange}
                    />
                </div>
                {cVar && (
                    <div className="bg-base-100 rounded-lg p-3">
                        <ColorLegend
                            showHeader={false}
                            cVar={options.indicators.cVar}
                            data={points}
                            featureFilter={search.featureFilter}
                            zoomToFiltered={search.zoomToFiltered}
                            colorScale={search.colorScale}
                            colorType={search.colorType}
                            scaleType={search.scaleType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
