import { useTranslation } from 'react-i18next';
import { DataPackage } from '../../types';
import { getRouteApi } from '@tanstack/react-router';

const routeApi = getRouteApi('/');

export function DataPackageSelect(props: {
    selectedDataPackage: DataPackage | null;
    setSelectedDatapackage: (dp: DataPackage) => void;
    dataPackageMap: Record<string, DataPackage>;
    dataPackages: DataPackage[];
}) {
    const { t, i18n } = useTranslation();

    const navigate = routeApi.useNavigate();
    const search = routeApi.useSearch();

    const selectDataPackageDescription =
        props.selectedDataPackage?.description?.[i18n.language] ??
        props.selectedDataPackage?.description?.['sv'];

    return (
        <>
            <h2 className="text-lg">{t('fardiga-visualiseringar')}</h2>
            <select
                className="select select-primary w-full max-w-xs"
                value={props.selectedDataPackage?.id ?? 'placeholder'}
                style={{
                    opacity: props.selectedDataPackage ? 1 : 0.6,
                }}
                onChange={(e) => {
                    const targetDataPackage =
                        props.dataPackageMap[e.target.value];
                    props.setSelectedDatapackage(targetDataPackage);

                    navigate({
                        replace: true,
                        search: {
                            ...search,
                            animationBuster: new Date().getTime(),
                        },
                    });
                }}
            >
                <option disabled value="placeholder">
                    {t('custom-package')}
                </option>
                {props.dataPackages.map((dp) => (
                    <option key={dp.id} value={dp.id}>
                        {dp.name[i18n.language] ?? dp.name['sv']}
                    </option>
                ))}
            </select>
            {selectDataPackageDescription && (
                <p className="text-sm pl-5">{selectDataPackageDescription}</p>
            )}
        </>
    );
}
